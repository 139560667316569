import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`;

const Layout = ({ children }) => {

  const baseDomain = process.env.TRAILHEAD_DOMAIN || 'https://trailhead.salesforce.com';

  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content="" />
            <meta property="og:title" content={siteMetadata.title} />
            <meta property="og:description" content={siteMetadata.description} />
            <link rel="canonical" href={`${baseDomain}/slack`} />
            <link
              rel="stylesheet"
              media="all"
              href="https://developer.salesforce.com/resources2/fonts/Nexa/Nexa.css"
            />
            
            <script src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />
          </Helmet>
          <div id="trblcomm" className="trblcomm">
            {children}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
